<template>
  <!--还款方式快速设置 暂时不用-->
  <div class="repaymentType">
    <base-dialog
      :visible.sync="visible"
      width="800px"
      title="还款方式"
      class="repaymentDialog"
      :showFooter="false"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <div style="padding: 20px 10px" class="repayment">
        <BaseButton
          label="预览"
          icon="iconfont iconyulan"
          @click="submit('preview')"
          class="preview"
        ></BaseButton>
        <div class="item">
          <title-name-slot title="还款时间" :required="true"></title-name-slot>
          <div class="itemcontent">
            <div class="content">
              <el-radio v-model="repaymentTime" label="1">距离到期前</el-radio>
              <el-input
                placeholder="请输入"
                style="width: 200px"
                :disabled="repaymentTime == 2"
                v-model="FinRepayPlanDTO.advanceRepayTerm"
                @blur="blurDate"
                maxlength="5"
                @input="inputDate"
              ></el-input
              >月开始还款。<span class="tips">
                <i class="iconfont iconshijian"></i>
                时间段预览：【{{ startDate.replace(/-/g, "/") }}】至 【{{
                  endDate.replace(/-/g, "/")
                }}】</span
              >
            </div>
            <div class="content">
              <div class="add">
                <el-radio v-model="repaymentTime" label="2"
                  >自定义还款时间</el-radio
                >
                <div class="addbtn">
                  <div
                    class="addbutton"
                    @click="addCustomData"
                    v-if="repaymentTime == 2"
                  >
                    <i class="el-icon-plus"></i>
                  </div>
                </div>
              </div>

              <ul v-if="repaymentTime == 2">
                <li v-for="(item, index) in customList" :key="index">
                  <div class="add">
                    <div class="Customtime">
                      第{{ index + 1 }}期还款时间
                      <el-date-picker
                        type="date"
                        :disabled="repaymentTime == 1"
                        :picker-options="pickerOptions"
                        v-model="item.date"
                        placeholder="请选择"
                        value-format="yyyy-MM-dd"
                        style="width: 150px !important"
                      ></el-date-picker>
                      <!-- <el-input
                        v-model="item.date"
                        style="width: 200px"
                      ></el-input> -->
                      <span class="tips">
                        <i class="iconfont iconshijian"></i>
                        还款时间预览：【{{
                          item.date ? item.date.replace(/-/g, "/") : ""
                        }}】</span
                      >
                    </div>
                    <div class="addbtn">
                      <div class="addbutton" @click="removeCustom(index)">
                        <i class="el-icon-minus"></i>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="item">
          <title-name-slot title="还款金额" :required="true">
            <div slot="operation">
              <el-radio v-model="FinRepayPlanDTO.repayType" label="1"
                >按金额</el-radio
              >
              <el-radio v-model="FinRepayPlanDTO.repayType" label="2"
                >按百分比</el-radio
              >
            </div>
          </title-name-slot>
          <div class="itemcontent lang">
            <div class="content">
              <div
                v-for="(item, index) of repaymentAmountArr"
                :key="index"
                style="padding-bottom: 10px"
              >
                <span style="padding-right: 10px"
                  >第{{ index + 1 }}期还款金额</span
                >
                <Amountinput
                  class="amount"
                  @input="inputMoney(repaymentAmount[index].repayAmount, index)"
                  @change="
                    changeMoney(repaymentAmount[index].repayAmount, index)
                  "
                  placeholder="请输入"
                  v-model="repaymentAmount[index].repayAmount"
                  v-if="
                    FinRepayPlanDTO.repayType && FinRepayPlanDTO.repayType == 1
                  "
                ></Amountinput>
                <proportion-input
                  v-if="
                    FinRepayPlanDTO.repayType && FinRepayPlanDTO.repayType == 2
                  "
                  class="amount"
                  style="width: 245px !important"
                  placeholder="请输入"
                  v-model="repaymentAmount[index].repayAmount"
                   @input="inputProportion(repaymentAmount[index].repayAmount, index)"
                   @change="
                    changeMoney(repaymentAmount[index].repayAmount, index)
                  "
                  @blur="
                    blurChargeRate(repaymentAmount[index].repayAmount, index)
                  "
                ></proportion-input>
                <span>{{ unit }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template slot="footer">
        <base-button label="提 交" @click="submit"></base-button>
        <base-button
          label="关 闭"
          type="default"
          @click="visible = false"
        ></base-button>
      </template>
    </base-dialog>
    <!-- 预览分批还款弹窗 -->
    <base-dialog
      :visible.sync="viewVisible"
      width="800px"
      title="预览"
      class="repaymentDialog"
      :showFooter="false"
    >
      <base-table
        :columns="columns"
        :showPage="false"
        :tableAttrs="{
          data: tableData,
          stripe: true,
        }"
        :isCaculateHeight="false"
        ref="tableData"
        :webPage="true"
      >
        <template slot="index" slot-scope="scope">{{
          scope.$index + 1
        }}</template>
      </base-table>
      <template slot="footer">
        <base-button
          label="关 闭"
          type="default"
          @click="viewVisible = false"
        ></base-button>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import titleNameSlot from '@/pages/dashboard/components/title-name-slot.vue'
import baseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { viewList } from '../utils/financing-info-config'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import Amountinput from '@/components/input/amount-input/amount-input'
import { contactApi } from '@/api/contactApi'
import ProportionInput from '@/components/input/proportion-input/proportion-input.vue'
export default {
  name: 'repaymentType',
  components: {
    baseDialog,
    BaseButton,
    BaseTable,
    titleNameSlot,
    Amountinput,
    ProportionInput
  },
  props: {
    schemeInfoList: Array,
    formData: Object
  },
  data () {
    return {
      repaymentAmount: [],
      unit: '元',
      repaymentAmountArr: [], // 还款金额数组
      visible: false,
      customList: [],
      repaymentTime: '',
      repaymentMoney: '',
      viewVisible: false,
      tableData: [],
      startDate: '',
      endDate: '',
      FinRepayPlanDTO: {
        advanceRepayTerm: '', // 提前还款期限
        businessId: '', // 业务ID
        chargeParty: '', // 收费主体
        finPlanId: '', // 融资方案ID
        repayAmount: '', // 还款金额
        repayRate: '', // 还款利率
        repayStatus: '', // 还款方式
        repayTime: '', // 还款时间(多个时间用逗号隔开)
        repayType: '' // 还款方式（ 1按照每月金额 2按照固定利率）
      },
      pickerOptions: {
        disabledDate (v) {
          return v.getTime() < new Date().getTime() - 86400000
        }
      }
    }
  },
  watch: {
    repaymentTime (val) {
      this.repaymentAmountArr = []
      this.repaymentAmount = []
      if (val === '1') {
        this.FinRepayPlanDTO.repayTime = ''
        this.customList = []
        this.customList.forEach((ele) => {
          ele.date = ''
        })
      } else if (val === '2') {
        this.FinRepayPlanDTO.advanceRepayTerm = ''
        this.startDate = ''
      }
    },
    'FinRepayPlanDTO.repayType': {
      handler (val) {
        if (val === '1') {
          this.unit = '元'
          this.FinRepayPlanDTO.repayRate = ''
        } else if (val === '2') {
          this.unit = '%'
          this.FinRepayPlanDTO.repayAmount = ''
        }
        this.repaymentAmount = []
        this.repaymentAmountArr.forEach((item) => {
          this.repaymentAmount.push({ repayAmount: '' })
        })
      },
      deep: true
    }
  },
  computed: {
    columns () {
      return viewList(this)
    }
  },
  methods: {
    blurDate () {
      if (!this.FinRepayPlanDTO.advanceRepayTerm || !this.endDate) return
      const str = this.endDate.replace(/-/g, '/')
      const yearItem =
        parseInt(this.FinRepayPlanDTO.advanceRepayTerm) >= 12
          ? parseInt(parseInt(this.FinRepayPlanDTO.advanceRepayTerm) / 12)
          : 0
      const monthItem =
        parseInt(this.FinRepayPlanDTO.advanceRepayTerm) < 12
          ? parseInt(this.FinRepayPlanDTO.advanceRepayTerm)
          : parseInt(this.FinRepayPlanDTO.advanceRepayTerm) % 12
      var date = new Date(str)
      var year = date.getFullYear() - yearItem
      var month = date.getMonth() - monthItem + 1
      if (month < 1) {
        year--
        month = 12 + month
      }
      if (month < 10) {
        month = '0' + month
      }
      var date2 = new Date(year, month, 0)
      var day1 = date.getDate()
      var day2 = date2.getDate()
      if (day1 > day2) {
        day1 = day2
      }
      if (day1 < 10) {
        day1 = '0' + day1
      }
      this.startDate = year + '-' + month + '-' + day1
    },
    endDateTime () {
      if (
        !this.schemeInfoList[this.$attrs.keyIndex].financeTerm ||
        !this.formData.preLoanDate
      ) {
        return
      }
      const advanceRepayTerm =
        this.schemeInfoList[this.$attrs.keyIndex].financeTerm
      const str = this.formData.preLoanDate.replace(/-/g, '/')
      const yearItem =
        parseInt(advanceRepayTerm) >= 12
          ? parseInt(parseInt(advanceRepayTerm) / 12)
          : 0
      const monthItem =
        parseInt(advanceRepayTerm) < 12
          ? parseInt(advanceRepayTerm)
          : parseInt(advanceRepayTerm) % 12
      var date = new Date(str)
      var year = date.getFullYear() + yearItem
      var month = date.getMonth() + monthItem + 1
      if (month > 12) {
        year++
        month -= 12
      }
      if (month < 10) {
        month = '0' + month
      }
      var date2 = new Date(year, month, 0)
      var day1 = date.getDate()
      var day2 = date2.getDate()
      if (day1 > day2) {
        day1 = day2
      }
      if (day1 < 10) {
        day1 = '0' + day1
      }
      this.endDate = year + '-' + month + '-' + day1
      console.log(this.endDate)
      this.blurDate()
    },
    inputDate (data) {
      this.repaymentAmount = []
      this.repaymentAmountArr = []
      const date = data.replace(/^[0]+[0-9]*$/gi, '').replace(/[^\d]/g, '')
      if (
        parseInt(date) + 1 >
        parseInt(this.schemeInfoList[this.$attrs.keyIndex].financeTerm)
      ) {
        this.FinRepayPlanDTO.advanceRepayTerm =
          this.schemeInfoList[this.$attrs.keyIndex].financeTerm - 1
        for (let i = 0; i < this.FinRepayPlanDTO.advanceRepayTerm; i++) {
          this.repaymentAmount.push({ repayAmount: '' })
          this.repaymentAmountArr.push({})
        }
        return this.warning('不能大于等于融资期限')
      } else {
        // 按期限生成数组
        for (let i = 0; i < data; i++) {
          this.repaymentAmount.push({ repayAmount: '' })
          this.repaymentAmountArr.push({})
        }
        this.FinRepayPlanDTO.advanceRepayTerm = date
      }
    },
    changeMoney (data, index) {
      // 限制不能输入0
      if (data === '0') {
        this.warning('还款金额不能为0')
        this.repaymentAmount[index].repayAmount = ''
      }
    },
    inputProportion (data, index) {
      let AmountAll = 0 // 总计输入百分比
      this.repaymentAmount.forEach((item) => {
        AmountAll = AmountAll + item.repayAmount * 1
      })
      if (100 - AmountAll < 0) {
        this.repaymentAmount[index].repayAmount = ''
        return this.warning('还款百分比不能大于100%')
      }
    },
    inputMoney (data, index) {
      const financeAmount = parseFloat(
        this.schemeInfoList[this.$attrs.keyIndex].financeAmount
      ) // 融资金额
      let AmountAll = 0 // 总计输入金额
      this.repaymentAmount.forEach((item) => {
        AmountAll = AmountAll + item.repayAmount * 1
      })
      AmountAll = parseFloat(AmountAll)
      console.log(financeAmount, AmountAll)
      if (AmountAll > financeAmount) {
        this.repaymentAmount[index].repayAmount = ''
        return this.warning('不能大于融资金额')
      }
      // if (parseFloat(data) / 10000 > parseFloat(this.schemeInfoList[this.$attrs.keyIndex].financeAmount)) {
      //   this.FinRepayPlanDTO.repayAmount = this.schemeInfoList[this.$attrs.keyIndex].financeAmount * 10000
      //   return this.warning('不能大于融资金额')
      // } else {
      //   this.FinRepayPlanDTO.repayAmount = data
      // }
    },
    show () {
      // if (
      //   !this.formData.preLoanDate ||
      //   !this.schemeInfoList[this.$attrs.keyIndex].financeTerm ||
      //   !this.schemeInfoList[this.$attrs.keyIndex].financeAmount
      // ) {
      //   return this.warning('请确认预计放款时间、融资期限、融资金额是否填写')
      // }
      this.repaymentTime = ''
      this.repaymentMoney = ''
      this.startDate = ''
      this.endDate = ''
      if (this.schemeInfoList.length < this.$attrs.keyIndex) {
        this.FinRepayPlanDTO = {
          advanceRepayTerm: '', // 提前还款期限
          businessId: '', // 业务ID
          chargeParty: '', // 收费主体
          finPlanId: '', // 融资方案ID
          repayAmount: '', // 还款金额
          repayRate: '', // 还款利率
          repayStatus: this.$attrs.value, // 还款方式
          repayTime: '', // 还款时间(多个时间用逗号隔开)
          repayType: '' // 还款方式（ 1按照每月金额 2按照固定利率）
        }
      } else {
        const item = this.schemeInfoList
        if (item.length > 0) {
          this.FinRepayPlanDTO = item[this.$attrs.keyIndex].finRepayStyleDTO
            ? JSON.parse(
              JSON.stringify(item[this.$attrs.keyIndex].finRepayStyleDTO)
            )
            : {
              advanceRepayTerm: '', // 提前还款期限
              businessId: '', // 业务ID
              chargeParty: '', // 收费主体
              finPlanId: '', // 融资方案ID
              repayAmount: '', // 还款金额
              repayRate: '', // 还款利率
              repayStatus: this.$attrs.value, // 还款方式
              repayTime: '', // 还款时间(多个时间用逗号隔开)
              repayType: ''
            }
          if (
            parseInt(this.FinRepayPlanDTO.advanceRepayTerm) + 1 >
            parseInt(this.schemeInfoList[this.$attrs.keyIndex].financeTerm)
          ) {
            this.FinRepayPlanDTO.advanceRepayTerm =
              this.schemeInfoList[this.$attrs.keyIndex].financeTerm - 1
          }
          const timeArr = this.FinRepayPlanDTO.repayTime.split(',')
          if (this.FinRepayPlanDTO.repayTime !== '') {
            if (this.customList.length < timeArr.length) {
              timeArr.forEach((item, index) => {
                this.customList.push({ date: item })
              })
            }
            this.repaymentTime = '2'
          } else {
            this.repaymentTime = '1'
          }
          this.$set(this.FinRepayPlanDTO, 'repayType', '')
          if (parseFloat(this.FinRepayPlanDTO.repayAmount)) {
            this.FinRepayPlanDTO.repayType = '1'
          } else if (parseFloat(this.FinRepayPlanDTO.repayRate)) {
            this.FinRepayPlanDTO.repayType = '2'
          } else {
            this.FinRepayPlanDTO.repayType = '1'
          }
          // 回显还款方式
          this.repaymentAmount = []
          this.repaymentAmountArr = []
          let arr = []
          if (this.FinRepayPlanDTO.repayAmount) {
            arr = this.FinRepayPlanDTO.repayAmount.split(',')
            this.$nextTick(() => {
              arr.forEach((item, index) => {
                this.repaymentAmountArr.push({})
              })
            })
            this.$nextTick(() => {
              arr.forEach((item, index) => {
                this.$set(this.repaymentAmount, index, { repayAmount: item })
              })
            })
          }
          if (this.FinRepayPlanDTO.repayRate) {
            arr = this.FinRepayPlanDTO.repayRate.split(',')
            arr.forEach((item, index) => {
              this.repaymentAmountArr.push({})
            })
            this.$nextTick(() => {
              arr.forEach((item, index) => {
                this.$set(this.repaymentAmount, index, { repayAmount: item })
              })
            })
          }
        }
      }
      this.visible = true
      this.$nextTick(() => {
        this.endDateTime()
      })
    },
    // 新增自定义数据
    addCustomData () {
      const length = this.customList.length + 1
      console.log(length)
      if (
        parseInt(this.schemeInfoList[this.$attrs.keyIndex].financeTerm) ===
        length
      ) {
        return this.warning('自定义还款时间不能超过融资期限')
      }
      this.customList.push({ date: '' })
      this.repaymentAmount.push({ repayAmount: '' })
      this.repaymentAmountArr.push({})
    },
    blurChargeRate (data, index) {
      const reg = /^100$|^(\d|[1-9]\d)(\.\d{1,2})?$/
      if (data && !reg.test(data)) {
        this.repaymentAmount[index].repayAmount = 0
        this.warning('数据格式不正确，请重新数据')
      }
      let AmountAll = 0 // 总计百分比
      this.repaymentAmount.forEach((item) => {
        AmountAll = AmountAll + item.repayAmount * 1
      })
      if (AmountAll > 100) {
        this.warning('还款比例相加不能大于100%')
        this.repaymentAmount[index].repayAmount = 0
      }
    },
    // 删除自定义
    removeCustom (index) {
      this.customList.splice(index, 1)
      this.repaymentAmount.splice(index, 1)
      this.repaymentAmountArr.splice(index, 1)
    },
    submit (type) {
      if (!this.repaymentTime) {
        return this.warning('请选择还款时间')
      }
      if (!this.formData.preLoanDate) {
        return this.warning('请选择预计放款时间')
      }
      // 还款时间
      if (this.repaymentTime === '1') {
        if (!this.FinRepayPlanDTO.advanceRepayTerm) {
          return this.warning('请输入还款时间')
        }
      } else {
        if (this.customList.length === 0) {
          return this.warning('请输入自定义还款时间')
        } else {
          let status = true
          this.customList.forEach((ele) => {
            if (!ele.date) {
              status = false
            }
          })
          if (!status) {
            return this.warning('请输入自定义还款时间')
          } else {
            const time = []
            this.customList.forEach((ele) => {
              time.push(ele.date)
            })
            this.FinRepayPlanDTO.repayTime = time.join(',')
          }
        }
      }
      if (!this.FinRepayPlanDTO.repayType) {
        return this.warning('请选择还款金额')
      }
      const flag = this.repaymentAmount.some((item) => {
        return item.repayAmount === ''
      })
      console.log(flag)
      if (flag) {
        if (this.FinRepayPlanDTO.repayType === '1') {
          return this.warning('请输入每月还款本金金额')
        } else {
          return this.warning('请输入每月还款本金比例')
        }
      }
      // if (this.FinRepayPlanDTO.repayType === '1') {
      //   // if (!this.FinRepayPlanDTO.repayAmount) {
      //   //   return this.warning('请输入每月还款本金金额')
      //   // }

      // } else {
      //   if (!this.FinRepayPlanDTO.repayRate) {

      //   }
      // }
      const newArr = []
      this.repaymentAmount.forEach((item) => {
        newArr.push(item.repayAmount)
      })
      const repayAmount = newArr.join()
      if (type === 'preview') {
        console.log(repayAmount, '还款金额')
        if (this.FinRepayPlanDTO.repayType === '1') {
          this.FinRepayPlanDTO.repayAmount = repayAmount
          this.FinRepayPlanDTO.repayRate = ''
        } else {
          this.FinRepayPlanDTO.repayAmount = ''
          this.FinRepayPlanDTO.repayRate = repayAmount
        }

        if (!this.schemeInfoList[this.$attrs.keyIndex].financeAmount) {
          return this.warning('请输入融资金额')
        }
        this.tableData = []
        this.viewVisible = true
        const params = {
          ...this.FinRepayPlanDTO,
          financeAmount:
            this.schemeInfoList[this.$attrs.keyIndex].financeAmount,
          financeEndDate: this.endDate,
          preLoanDate: this.formData.preLoanDate.replace(/\//g, '-')
        }
        contactApi.previewRepayPlan(params).then((res) => {
          if (res.success) {
            this.tableData = res.data
          }
        })
      } else {
        if (this.FinRepayPlanDTO.repayType === '1') {
          this.FinRepayPlanDTO.repayAmount = repayAmount
          this.FinRepayPlanDTO.repayRate = ''
        } else {
          this.FinRepayPlanDTO.repayAmount = ''
          this.FinRepayPlanDTO.repayRate = repayAmount
        }
        this.FinRepayPlanDTO.financeEndDate = this.endDate
        this.FinRepayPlanDTO.preLoanDate = this.formData.preLoanDate.replace(/\//g, '-')
        console.log(this.FinRepayPlanDTO, '222')
        this.$emit('repaymentType', {
          data: this.FinRepayPlanDTO,
          key: this.$attrs.keyIndex
        })
        this.visible = false
      }
    },
    change (val) {
      // console.log(this.$attrs.value, val, 111)
      this.FinRepayPlanDTO = {
        advanceRepayTerm: '', // 提前还款期限
        chargeParty: '', // 收费主体
        finPlanId: '', // 融资方案ID
        repayAmount: '', // 还款金额
        repayRate: '', // 还款利率
        repayStatus: val, // 还款方式
        repayTime: '', // 还款时间(多个时间用逗号隔开)
        repayType: '', // 还款方式（ 1按照每月金额 2按照固定利率）
        financeEndDate: this.endDate,
        preLoanDate: this.formData.preLoanDate.replace(/\//g, '-')
      }
      this.$emit('repaymentType', {
        data: this.FinRepayPlanDTO,
        key: this.$attrs.keyIndex
      })
    }
  }
}
</script>

<style lang='scss' scoped>
.itemcontent {
  background: #f9f9f6;
  padding: 16px 10px;
  margin-top: 11px;
  .Customtime {
    padding-left: 24px;
  }
  .tips {
    font-size: 14px;
    font-weight: 400;
    color: #757380;
  }
  .add {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .addbtn {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    /deep/ .iconjian {
      color: white;
    }
    .addbutton {
      background: #4A7CF0;
      width: 20px;
      height: 20px;
      color: #fff;
      text-align: center;
      line-height: 20px;
      cursor: pointer;
      &:hover {
        background-color: #6996FF;
      }
    }
  }
  /deep/ .el-radio {
    margin-right: 10px;
  }
  /deep/ .el-input--small {
    width: 90px !important;
    margin-right: 10px;
  }
  /deep/ .el-input__inner {
    border-color: #D9E5EE;
  }
  .amount {
    width: 245px !important;
    display: inline-block;
    /deep/.el-input {
      width: 245px !important;
    }
  }
}

.repaymentType {
  .showBtn {
    width:calc(100% - 50px) !important;
  }

  .set {
    color: #fff;
    cursor: pointer;
    margin-left: 10px;
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    display: inline-block;
    background: linear-gradient(90deg, #285ED7 0%, #749EFF 100%);
    border-radius: 50%;
  }
}
.repayment {
  .item {
    margin-bottom: 10px;
    border-bottom: 1px dashed #ccc;
    .content {
      margin-bottom: 10px;
      .el-icon {
        cursor: pointer;
      }
      ul {
        margin: 0px;
        padding: 0px;
        margin-top: 10px;
        li {
          margin-bottom: 10px;
        }
      }
    }
  }
}
.repaymentDialog {
  margin-top: 12vh !important;
  /deep/ .el-dialog {
    height: 60vh !important;
  }
  /deep/ .el-dialog__body {
    height: 450px;
  }
  .preview {
    height: 34px;
    padding: 0;
    width: 90px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    /deep/ .iconchakan {
      font-size: 20px;
    }
  }
  /deep/ .el-input__validateIcon {
    display: none;
  }
}
</style>
