<template>
  <div>
    <el-input
      :value="value"
      v-bind="$attrs"
      v-on="currentListeners"
      @blur="blur"
    ></el-input>
  </div>
</template>

<script>
import { EmojiReg } from '@/config/reg-config'
export default {
  name: 'proportionInput',
  props: {
    value: {}
  },
  dat () {
    return {
      input: ''
    }
  },
  computed: {
    currentListeners () {
      return Object.assign({}, this.$listeners, {
        input: this.handleInput
      })
    }
  },
  methods: {
    // 失去焦点验证小数点后有无数字
    blur () {
      let value = this.value
      if (!value) {
        return
      }
      const arr = value.toString().split('.')
      if (arr.length > 1 && !arr[1]) {
        value = arr[0]
      }
      this.$emit('input', value)
    },
    handleInput (e) {
      // 替换掉表情输入
      let value = e.replace(EmojiReg, '')
      // 替换 首位为0 输入不为 . 的字符
      const numberarr = value.split('')
      if (
        numberarr[0] === '0' &&
        numberarr.length > 1 &&
        numberarr[1] !== '.'
      ) {
        return
      }
      // 替换 首位为 . 和多个 .
      if (value.indexOf('.') > -1) {
        const arr = value.split('.')
        if (!arr[0]) {
          return
        }
        if (arr.length > 2) {
          return
        }
      }
      if (value && value > 100) {
        value = 100
        return
      } else if (value && value < 0) {
        value = 0
        return
      }
      // 只能为0-100数字和最多两位小数
      value = value
        .replace(/[^\d.]/g, '') // 清除“数字”和“.”以外的字符
        .replace(/^\./g, '')// 验证第一个字符是数字而不是
        .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
        .replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
        .replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      this.$emit('input', value)
    }
  }
}
</script>
